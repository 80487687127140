<template>
  <div>
    <Table
      label="Post Comments"
      :actions="actions"
      :items="$store.getters['postComments/tableData']"
      :fields="fields"
      :loading="$store.state.postComments.loadingTable"
      :pagination="$store.state.postComments.tablePagination"
      :sort-options="sortOptions"
      @load="loadData"
      @paginate="paginate"
    />
    <!-- Delete post comment modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Comment"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteComment"
    >
      Are you sure want to delete this comment?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      deleteModalVisible: false,
      selectedData: '',
      sortOptions: [],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'authorUser_nickname',
          label: 'User',
          type: 'profile',
          image: 'authorUser_avatar',
          sortable: false,
        },
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'email',
          type: 'email',
          label: 'Email',
        },
      ],

      actions: [{
        type: 'other',
        children: [
          {
            label: 'Delete',
            event: this.confirmDelete,
            visibility: {
              callback: data => !data.deletedAt && this.canAccess('manage', 'PostComments'),
            },
          },
        ],
      }],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      const { id } = this.$route.params
      return this.$store.dispatch('postComments/getTableData', id)
    },
    paginate(val) {
      this.$store.commit('postComments/SET_TABLE_PAGINATION', val)
    },
    deleteComment() {
      this.$store.dispatch('postComments/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The comment has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
  },
}
</script>
